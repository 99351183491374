import React, { useEffect, useState } from "react";
import categors from "../dummydata/categories.json";
import ServerTable from "../components/ServerTable";
import { categCol } from "../components/columns";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import loader from "../assets/001 EN.gif";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as yup from "yup";
import { Unstable_NumberInput as BaseNumberInput } from "@mui/base/Unstable_NumberInput";
import InputLabel from "@mui/material/InputLabel";
import { axiosInstance } from "../config/axiosInstance";
import swal from "sweetalert";
import axios from "axios";
import "../styles/inputStyle.css";
import CircularProgress from "@mui/material/CircularProgress";

function Categories() {
  const nav = useNavigate();
  const [loading, setLoading] = useState(true);
  const [theme, setTheme] = useState("");
  const [number, setNumber] = useState(0);
  const [type, setType] = useState("all");
  const [selected, setSelected] = useState(null);
  const [val, setVal] = useState("");
  const [categories, setCategories] = useState(null);
  const [reload, setReload] = useState(false);
  const [delet, setDelet] = useState(false);
  const columns = categCol(clickCateg, editCateg, deleteCateg);
  function clickCateg(id) {
    nav(`/category/${id}`);
  }
  function editCateg(id) {
    nav(`/editcategory/${id}`);
  }
  function deleteCateg(id) {
    setDelet(true);
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;
    axios
      .get("https://api.2ladue.com/sanctum/csrf-cookie")
      .then((response) => {
        axiosInstance
          .delete(`modules/product-category/${id}`)
          .then((res) => {
            swal("Category deleted successfully");
            setDelet(false);
            setReload(!reload);
          })
          .catch((err) => {
            console.log(err);
            swal("Somthing went wrong");
            setDelet(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setDelet(false);
      });
  }
  const daymode = useSelector((state) => {
    return state.switchMode.mode;
  });
  useEffect(() => {
    const thememode = localStorage.getItem("theme");
    setTheme(thememode);
  }, [daymode]);
  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;
    axios
      .get("https://api.2ladue.com/sanctum/csrf-cookie")
      .then((response) => {
        axiosInstance
          .get("modules/product-category")
          .then((res) => {
            setCategories(res.data.data.data);
            setSelected(res.data.data.data);
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
            if(err.response?.status !== 404 && err.response?.data?.message !== "No Data Found"){
              swal("Something went wrong");
            }
            setLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
        swal("Something went wrong");
        setLoading(false);
      });
  }, [reload]);
  return (
    <>
      {loading ? (
        <div
          className=" d-flex justify-content-center"
          style={{ height: "100vh", backgroundColor: "#fcfcfb" }}
        >
          <img alt="loading" src={loader} className="loading-img" />
        </div>
      ) : (
        <>
          {delet && (
            <div className="sticky-top position-absolute h-100 w-100 z-3 d-flex justify-content-center align-items-center">
              <CircularProgress size={75} />
            </div>
          )}
          <div
            style={{ width: "100%", minHeight: "100vh" }}
            className={`${theme === "Night" ? "bg-dark" : "bg-light"} py-5`}
          >
            <div className="container">
              <ServerTable
                title="Categories"
                type={type}
                setType={setType}
                data={selected? selected : []}
                columns={columns}
                search={
                  <div className="search-box">
                    <button className="btn-search">
                      <SearchIcon />
                    </button>
                    <input
                      type="text"
                      className="input-search"
                      placeholder="Search"
                      value={val}
                      onChange={(e) => {
                        setVal(e.target.value);
                        if (e.target.value === "") {
                          setSelected(categories);
                        } else {
                          const filtered = categories?.filter((item, index) => {
                            return item.title
                              .toLowerCase()
                              .includes(e.target.value);
                          });
                          setSelected(filtered);
                        }
                      }}
                    />
                  </div>
                }
                //   filters={["Electronics", "Furniture", "Perfumes", "Food"]}
                addButton={
                  <Button
                    // data-bs-toggle="modal"
                    // data-bs-target="#exampleModal"
                    variant="contained"
                    style={{ fontSize: "0.9rem" }}
                    onClick={() => nav("/addcategory")}
                  >
                    <AddIcon style={{ marginRight: 5 }} /> {"Add Category"}
                  </Button>
                }
              />
            </div>
            {/* <div
            className="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <form
              className="modal-dialog modal-dialog-centered"
              onSubmit={formik.handleSubmit}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    Add New Category
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <Grid container>
                    <Grid item xs={12} sx={{ my: 2 }}>
                      <TextField
                        id="outlined-basic"
                        label="Title"
                        variant="outlined"
                        name="title"
                        error={formik?.errors?.title}
                        fullWidth
                        helperText={formik?.errors?.title}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.title}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div> */}
          </div>
        </>
      )}
    </>
  );
}

export default Categories;
