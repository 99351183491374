import React, { useEffect, useState } from "react";
import prods from "../dummydata/prods.json";
import { useLocation, useNavigate } from "react-router-dom";
import "react-slideshow-image/dist/styles.css";
import { Slide } from "react-slideshow-image";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { axiosInstance } from "../config/axiosInstance";
import prod from "../assets/prod.png";
import loader from "../assets/001 EN.gif";
import swal from "sweetalert";
import axios from "axios";

function Product() {
  const nav = useNavigate();
  const url = useLocation();
  const prodid = url.pathname.split("/");
  const [theme, setTheme] = useState("");
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const daymode = useSelector((state) => {
    return state.switchMode.mode;
  });
  useEffect(() => {
    const thememode = localStorage.getItem("theme");
    setTheme(thememode);
  }, [daymode]);
  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;
    axios
      .get("https://api.2ladue.com/sanctum/csrf-cookie")
      .then((response) => {
        axiosInstance
          .get(`/modules/product/${prodid[prodid.length - 1]}`)
          .then((res) => {
            setProduct(res.data.data);
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const EditStyles = styled("div")(({ theme }) => ({
    cursor: "pointer",
    backgroundColor: "#ffe000",
    "&:hover": {
      transform: "scale(1.2)",
    },
  }));
  const DeleteStyles = styled("div")(({ theme }) => ({
    cursor: "pointer",
    backgroundColor: "#ff485e",
    "&:hover": {
      transform: "scale(1.2)",
    },
  }));
  return (
    <>
      {loading ? (
        <div
          className=" d-flex justify-content-center"
          style={{ height: "100vh", backgroundColor: "#fcfcfb" }}
        >
          <img alt="loading" src={loader} className="loading-img" />
        </div>
      ) : (
        <div
          className={`${theme === "Night" ? "bg-dark" : "bg-light"} py-5`}
          style={{ width: "100%", minHeight: "100vh" }}
        >
          <div className="container">
            <div
              className="p-3 rounded-3 mb-3"
              style={{
                boxShadow: "1px 1px 10px 0px lightgrey",
                backgroundColor: theme === "Night" ? "#202442" : "white",
              }}
            >
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="fs-3 fw-semibold text-secondary">
                  {product?.title ? product?.title : "-"}
                </div>
                <div className="d-flex justify-content-end gap-3">
                  <EditStyles
                    className="p-2 rounded-2"
                    onClick={() =>
                      nav(`/editproduct/${prodid[prodid.length - 1]}`)
                    }
                  >
                    <EditOutlinedIcon style={{ color: "white" }} />
                  </EditStyles>
                  <DeleteStyles
                    className="p-2 rounded-2"
                    onClick={() => {
                      axios.defaults.withCredentials = true;
                      axios.defaults.withXSRFToken = true;
                      axios
                        .get("https://api.2ladue.com/sanctum/csrf-cookie")
                        .then((response) => {
                          axiosInstance
                            .delete(
                              `modules/product/${prodid[prodid.length - 1]}`
                            )
                            .then((res) => {
                              swal("Product deleted successfully");
                              nav("/products");
                            })
                            .catch((err) => {
                              console.log(err);
                              swal("Something went wrong");
                            });
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }}
                  >
                    <DeleteForeverOutlinedIcon style={{ color: "white" }} />
                  </DeleteStyles>
                </div>
              </div>
              <div
                className="view-page mb-5"
                style={{ fontSize: "0.8rem" }}
              >
                <div className="w-100">
                  {/* <div className="d-flex justify-content-between py-2">
                    <div className="text-secondary fw-semibold">
                      Arabic Title
                    </div>
                    <div
                      style={{ color: theme === "Night" ? "white" : "black" }}
                    >
                      {product?.title?.ar ? product?.title?.ar : "-"}
                    </div>
                  </div>     */}
                  <div className="d-flex justify-content-between py-2">
                    <div className="text-secondary fw-semibold me-5">
                      Description
                    </div>
                    <div
                      className="text-end text-capitalize"
                      style={{ color: theme === "Night" ? "white" : "black" }}
                    >
                      {product?.description ? product?.description : "-"} 
                    </div>
                  </div>
                  <div className="d-flex justify-content-between py-2">
                    <div className="text-secondary fw-semibold me-5">
                      Arabic Description
                    </div>
                    <div 
                      className="text-end text-capitalize"
                      style={{ color: theme === "Night" ? "white" : "black" }}
                    >
                      {product?.description_ar ? product?.description_ar : "-"}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between py-2">
                    <div className="text-secondary fw-semibold">
                      Purchasable
                    </div>
                    <div
                      className="w-50 text-end text-capitalize"
                      style={{ color: theme === "Night" ? "white" : "black" }}
                    >
                      {product?.purchasable
                        ? product?.purchasable.toString()
                        : "-"}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between py-2">
                    <div className="text-secondary fw-semibold">Category</div>
                    <div className=" text-capitalize"
                      style={{ color: theme === "Night" ? "white" : "black" }}
                    >
                      {product?.category?.title
                        ? product?.category?.title
                        : "-"}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between py-2">
                    <div className="text-secondary fw-semibold">Type</div>
                    <div
                      className=" text-capitalize"
                      style={{ color: theme === "Night" ? "white" : "black" }}
                    >
                      {product?.type ? product?.type : "-"}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between py-2">
                    <div className="text-secondary fw-semibold">Added by</div>
                    <div
                      className=" text-capitalize"
                      style={{ color: theme === "Night" ? "white" : "black" }}
                    >
                      {product?.added_by ? product?.added_by : "-"}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between py-2">
                    <div className="text-secondary fw-semibold">
                      Display Order
                    </div>
                    <div
                      className=" text-capitalize"
                      style={{ color: theme === "Night" ? "white" : "black" }}
                    >
                      {product?.display_order
                        ? product?.display_order
                        : "-"}
                    </div>
                  </div>
                </div>
                <div className="w-100">
                  <div className="d-flex justify-content-between py-2">
                    <div className="text-secondary fw-semibold">Quantity</div>
                    <div
                      style={{ color: theme === "Night" ? "white" : "black" }}
                    >
                      {product?.quantity ? product?.quantity : 0}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between py-2">
                    <div className="text-secondary fw-semibold">Current Quantity</div>
                    <div
                      className=" text-capitalize"
                      style={{ color: theme === "Night" ? "white" : "black" }}
                    >
                      {product?.current_quantity ? product?.current_quantity : "-"}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between py-2">
                    <div className="text-secondary fw-semibold">Main Quantity</div>
                    <div
                      className=" text-capitalize"
                      style={{ color: theme === "Night" ? "white" : "black" }}
                    >
                      {product?.main_quantity ? product?.main_quantity : "-"}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between py-2">
                    <div className="text-secondary fw-semibold">Minimum Quantity</div>
                    <div
                      className=" text-capitalize"
                      style={{ color: theme === "Night" ? "white" : "black" }}
                    >
                      {product?.minimum_quantity ? product?.minimum_quantity : "-"}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between py-2">
                    <div className="text-secondary fw-semibold">Price</div>
                    <div
                      style={{ color: theme === "Night" ? "white" : "black" }}
                    >
                      {product?.price ? product?.price : "-"} $
                    </div>
                  </div>
                  <div className="d-flex justify-content-between py-2">
                    <div className="text-secondary fw-semibold">
                      Direct Purchase Limit
                    </div>
                    <div
                      style={{ color: theme === "Night" ? "white" : "black" }}
                    >
                      {product?.direct_purchase_limit
                        ? product?.direct_purchase_limit
                        : "-"}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between py-2">
                    <div className="text-secondary fw-semibold">
                      Direct Purchase Limit Period
                    </div>
                    <div
                      className=" text-capitalize"
                      style={{ color: theme === "Night" ? "white" : "black" }}
                    >
                      {product?.direct_purchase_limit_period
                        ? product?.direct_purchase_limit_period
                        : "-"}
                    </div>
                  </div>
                </div>
              </div>
              {product?.image ? (
                <div className="p-4">
                  <div className="slide-container">
                    <Slide>
                      {product?.image.map((item, idx) => {
                        return (
                          <div
                            key={idx}
                            className="d-flex justify-content-center"
                          >
                            <img
                              alt={idx}
                              src={item.image}
                              width={"50%"}
                              height={"100%"}
                            />
                          </div>
                        );
                      })}
                    </Slide>
                  </div>
                </div>
              ) : (
                <div className="d-flex justify-content-center">
                  <img alt={product?.id} src={prod} width={"25%"} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Product;
