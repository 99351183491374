import React, { useEffect, useState } from "react";
import prods from "../dummydata/prods.json";
import ServerTable from "../components/ServerTable";
import { packageCol } from "../components/columns";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import loader from "../assets/001 EN.gif";
import { axiosInstance } from "../config/axiosInstance";
import swal from "sweetalert";
import axios from "axios";
import "../styles/inputStyle.css";
import CircularProgress from "@mui/material/CircularProgress";

function Packages() {
  const nav = useNavigate();
  const [theme, setTheme] = useState("");
  const columns = packageCol(auctionClick, editAuction, deleteAuction);
  const [sliders, setSliders] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [type, setType] = useState("all");
  const [selected, setSelected] = useState(null);
  const [val, setVal] = useState("");
  const [delet, setDelet] = useState(false);
  function auctionClick(id) {
    nav(`/package/${id}`);
  }
  function editAuction(id) {
    nav(`/editpackage/${id}`);
  }
  function deleteAuction(id) {
    setDelet(true);
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;
    axios
      .get("https://api.2ladue.com/sanctum/csrf-cookie")
      .then((response) => {
        axiosInstance
          .delete(`modules/package/${id}`)
          .then((res) => {
            swal("Package deleted successfully");
            setDelet(false);
            setReload(!reload);
          })
          .catch((err) => {
            console.log(err);
            swal("Something went wrong");
            setDelet(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setDelet(false);
      });
  }
  const daymode = useSelector((state) => {
    return state.switchMode.mode;
  });
  useEffect(() => {
    const thememode = localStorage.getItem("theme");
    setTheme(thememode);
  }, [daymode]);
  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;
    axios
      .get("https://api.2ladue.com/sanctum/csrf-cookie")
      .then((response) => {
        axiosInstance
          .get("/modules/package")
          .then((res) => {
            setSliders(res.data.data.data);
            setSelected(res.data.data.data);
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
            if(err.response?.status !== 404 && err.response?.data?.message !== "No Data Found"){
              swal("Something went wrong");
            }
            setLoading(false);
          });
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [reload]);
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: 10,
    backgroundColor: "#eaeaea",
    "&:hover": {
      // backgroundColor: "#ccc345",
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  }));
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    width: "100%",
    height: "100%",
    "& .MuiInputBase-input": {
      // padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "30ch",
        },
      },
    },
  }));
  return (
    <>
      {loading ? (
        <div
          className=" d-flex justify-content-center"
          style={{ height: "100vh", backgroundColor: "#fcfcfb" }}
        >
          <img alt="loading" src={loader} className="loading-img" />
        </div>
      ) : (
        <>
          {delet && (
            <div className="sticky-top position-absolute h-100 w-100 z-3 d-flex justify-content-center align-items-center">
              <CircularProgress size={75} />
            </div>
          )}
          <div
            style={{ width: "100%", minHeight: "100vh" }}
            className={`${theme === "Night" ? "bg-dark" : "bg-light"} py-5`}
          >
            <div className="container">
              <ServerTable
                title="Packages"
                type={type}
                setType={setType}
                data={selected? selected : []}
                columns={columns}
                search={
                  <div className="search-box">
                    <button className="btn-search">
                      <SearchIcon />
                    </button>
                    <input
                      type="text"
                      className="input-search"
                      placeholder="Search"
                      value={val}
                      onChange={(e) => {
                        setVal(e.target.value);
                        if (e.target.value === "") {
                          setSelected(sliders);
                        } else {
                          const filtered = sliders?.filter((item, index) => {
                            return item.title
                              .toLowerCase()
                              .includes(e.target.value);
                          });
                          setSelected(filtered);
                        }
                      }}
                    />
                  </div>
                }
                filters={["All", "Day", "Week", "Month", "Year"]}
                addButton={
                  <Button
                    variant="contained"
                    style={{ fontSize: "0.9rem" }}
                    onClick={() => nav("/addpackage")}
                  >
                    <AddIcon style={{ marginRight: 5 }} /> {"Add Package"}
                  </Button>
                }
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Packages;
